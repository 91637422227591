<template>
    <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>Dashboard - SB Admin</title>
        <link href="https://cdn.datatables.net/autofill/2.6.0/css/autoFill.dataTables.min.css" rel="stylesheet" />
        <!-- DataTables CSS -->
        <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.13.6/css/jquery.dataTables.min.css">

        <!-- DataTables Editor CSS -->
        <link rel="stylesheet" type="text/css" href="../../extensions/Editor/css/editor.dataTables.min.css">

        <!-- DataTables Select CSS -->
        <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/select/1.7.0/css/select.dataTables.min.css">

        <!-- DataTables Buttons CSS -->
        <link rel="stylesheet" type="text/css"
            href="https://cdn.datatables.net/buttons/2.4.2/css/buttons.dataTables.min.css">

        <!-- DataTables DateTime CSS -->
        <link rel="stylesheet" type="text/css"
            href="https://cdn.datatables.net/datetime/1.5.1/css/dataTables.dateTime.min.css">

        <!-- DataTables ColReorder CSS -->
        <link rel="stylesheet" type="text/css"
            href="https://cdn.datatables.net/colreorder/1.7.0/css/colReorder.dataTables.min.css">
        <!-- Include Chart.js library -->
    </head></template>
