<template>
    <!-- Footer Start -->
    <div class="container-fluid bg-dark text-light footer mt-5 py-4 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-3">
            <div class="row g-4">
                <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-3">Visit Us</h4>
                    <p class="mb-2"><i class="fa fa-map-marker-alt me-2"></i>123 Garden Avenue, New York, USA</p>
                    <p class="mb-2"><i class="fa fa-phone-alt me-2"></i>+012 345 67890</p>
                    <p class="mb-2"><i class="fa fa-envelope me-2"></i>info@yourrestaurant.com</p>
                    <div class="d-flex pt-2">
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-square btn-outline-light rounded-circle me-2" href="#"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-3">Our Services</h4>
                    <a class="btn btn-link" href="#">Menu</a>
                    <a class="btn btn-link" href="#">Reservations</a>
                    <a class="btn btn-link" href="#">Catering</a>
                    <a class="btn btn-link" href="#">Events</a>
                    <a class="btn btn-link" href="#">Gallery</a>
                </div>

                <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-3">Quick Links</h4>
                    <a class="btn btn-link" href="#">About Us</a>
                    <a class="btn btn-link" href="#">Contact Us</a>
                    <a class="btn btn-link" href="#">Menu</a>
                    <a class="btn btn-link" href="#">Reservations</a>
                    <a class="btn btn-link" href="#">Reviews</a>
                </div>

                <div class="col-lg-3 col-md-6">
                    <h4 class="text-white mb-3">Newsletter</h4>
                    <p>Subscribe to our newsletter to receive the latest updates and special offers.</p>
                    <div class="position-relative w-100">
                        <input class="form-control bg-light border-light w-100 py-2 ps-3 pe-4" type="email" placeholder="Your email">
                        <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i class="bi bi-arrow-up"></i></a>
</template>
