<template>
    <!-- About Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5 align-items-end">
                <div class="col-lg-3 col-md-5 wow fadeInUp" data-wow-delay="0.1s">
                    <img class="img-fluid rounded" data-wow-delay="0.1s" src="@/assets/img/about.jpg">
                </div>
                <div class="col-lg-6 col-md-7 wow fadeInUp" data-wow-delay="0.3s">
                    <h1 class="display-1 text-primary mb-0">72</h1>
                    <p class="text-primary mb-4">Year of Experience</p>
                    <h1 class="display-5 mb-4">Discover Culinary Excellence</h1>
                    <p class="mb-4">Experience the finest in dining, where every bite is a journey of taste. Our expert
                        chefs craft exquisite dishes to delight your palate.</p>
                    <a class="btn btn-primary py-3 px-4" href="">Explore Our Menu</a>
                </div>
                <div class="col-lg-3 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="row g-5">
                        <div class="col-12 col-sm-6 col-lg-12">
                            <div class="border-start ps-4">
                                <i class="fa fa-award fa-3x text-primary mb-3"></i>
                                <h4 class="mb-3">Our Accolades</h4>
                                <span>Discover the recognition and awards that celebrate our commitment to culinary
                                    excellence and exceptional service.</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12">
                            <div class="border-start ps-4">
                                <i class="fa fa-users fa-3x text-primary mb-3"></i>
                                <h4 class="mb-3">Our Culinary Artists</h4>
                                <span>Meet our passionate team of chefs and bartenders who work tirelessly to create
                                    extraordinary dishes and cocktails that take your dining experience to new
                                    heights.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>

  