<template>
    <!-- Service Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style="max-width: 500px;">
                <p class="fs-5 fw-bold text-primary">Restaurant and Bar Services</p>
                <h1 class="display-5 mb-5">Explore Our Exquisite Offerings</h1>
            </div>
            <div class="row g-4 justify-content-center">
                <!-- Service Item for Table Booking -->
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item rounded d-flex h-100">
                        <div class="service-img rounded">
                            <img class="img-fluid" src="@/assets/img/soup.jpg" alt="">
                        </div>
                        <div class="service-text rounded p-5">
                            <div class="btn-square rounded-circle mx-auto mb-3">
                                <img class="img-fluid" src="@/assets/img/icon/icon-6.png" alt="Icon">
                            </div>
                            <h4 class="mb-3">Table Booking</h4>
                            <p class="mb-4">Reserve your table and enjoy a delightful dining experience with our diverse
                                menu offerings.</p>
                            <a class="btn btn-sm" href="/booking"><i class="fa fa-calendar-check text-primary me-2"></i>Book
                                Table</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End --></template>
  